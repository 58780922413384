import { extendTheme } from '@chakra-ui/react';

const theme = {
  config: {
    useSystemColorMode: false,
    initialColorMode: 'dark',
  },
  fonts: {
    body: "'Share Tech Mono', system-ui, sans-serif",
    heading: "'Share Tech Mono', system-ui, sans-serif",
    mono: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New' ,monospace",
  },
  colors: {
    black: '#000000',
    white: '#FFFFFF',
    pink: '#F0D2F9',
    brand: {
      50: '#FFC6BF',
      100: '#ffb000',
      200: '#ff9700',
      300: '#ff9900',
      400: '#fc750d',
      500: '#fa6b24',
      600: '#f65246',
      700: '#f64950',
      800: '#f2216f',
    },
    gray: {
      50: '#F9F8F8',
      100: '#EFEFEF',
      200: '#E8E8E8',
      300: '#C9C9C9',
      400: '#A9A9A9',
      500: '#888888',
      600: '#535353',
      700: '#343434',
      800: '#222222',
      900: '#171717',
    },
  },
  styles: {
    global: {
      body: {
        height: '100%',
        margin: 0,
        overflowX: 'hidden',
      },
    },
  },
  components: {
    Button: {
      sizes: {
        primary: {
          h: '60px',
          minW: '300px',
          m: '1',
          borderRadius: 'xl',
        },
        secondary: {
          px: '3.15rem',
          py: '1rem',
          w: {
            base: 'full',
            md: 'auto',
          },
          minW: '300px',
          borderRadius: 'xl',
        },
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            color: 'white',
            _hover: {
              borderColor: 'gray.100',
            },
            _focus: {
              borderColor: 'gray.200',
            },
          },
        },
      },
    },
  },
};

export default extendTheme(theme);
