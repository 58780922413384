import '@fontsource/share-tech-mono';
import 'focus-visible/dist/focus-visible';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const wrapRootElement = ({ element }) => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require
    require('smooth-scroll')('a[href*="#"]', { speed: 200 });
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LeZ-h8dAAAAAHjArGLdXql1nuUB6L1Qzij6H7if">
      {element}
    </GoogleReCaptchaProvider>
  );
};
